.formio-component-datagrid .datagrid-table,
.formio-component-datagrid .datagrid-table td,
.formio-component-datagrid .datagrid-table th {
    border: none !important;
}
.formio-component-datagrid:not(.datagrid-disabled-class) .datagrid-table td {
    border-bottom: $table-border !important;
    vertical-align: baseline;
}
.formio-component-datagrid:not(.datagrid-disabled-class) .datagrid-table tfoot {
    tr {
        td {
            border: none !important;
            padding: 1.9rem 0 !important;
        }
    }
}
.formio-component-datagrid:not(.datagrid-disabled-class) {
    overflow-x: visible !important;
    margin-bottom: 0.5rem;
    .datagrid-table-empty-cell {
        display: none;
    }
    thead {
        tr {
            margin-bottom: 0.5rem;
            border-bottom: 0.16rem solid #D2D2D7;
            th {
                padding-left: 0!important;
            }
        }
    }
    tbody {
        tr:last-child {
            td {
                border: none !important;
            }
        }
        tr {
            td {
                padding: 0.625rem 0 !important;
                .mb-3 {
                    margin-bottom: 0 !important;
                }

                .field-required:after, .tab-error::after {
                    display: none;
                }
            }
            .field-required:after, .tab-error::after {
                display: none;
            }
        }
    }
}

.datagrid-disabled-class {
    .datagrid-table {
        border: none;
        thead {
            tr {
                margin-bottom: 0.5rem;
                border-bottom: $table-border;
                th {
                    font-size: 0.9rem;
                    line-height: 120%;
                    padding-bottom: 1rem;
                    font-weight: 400;
                    color: #86868B;
                    vertical-align: bottom;
                    text-align: right;
                }
                .field-required:after, .tab-error::after {
                    display: none;
                }
            }
            th:first-child {
                text-align: left;
            }
        }
        tbody {
            tr td:first-child {
                border-top-left-radius: 0.4rem;
                border-bottom-left-radius: 0.4rem;
                .choices__item {
                    justify-content: flex-start;
                    span {
                        text-align: left;
                    }
                }
                .choices__list {
                    justify-content: flex-start;
                    padding-left: 0;
                }
                .formio-component-htmlelement {
                    text-align: left;
                }
                .input-as-status {
                    justify-content: flex-start;
                }
                .formio-editor-read-only-content,
                .disabledValueContainer,
                .input-group {
                    justify-content: flex-start;
                    text-align: left;
                }
            }
            tr td:last-child {
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
                .card-body {
                    .formio-component-button {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 0.5rem;
                    }
                }
            }
            tr:hover {
                td {
                    background: #F5F5F7;
                }
            }
            .datagrid-table-empty-row {
                height: 6.25rem;
                .datagrid-table-empty-cell {
                    text-align: center;
                    margin: 2rem 0;
                    vertical-align: middle;
                }
            }
            .datagrid-table-empty-cell:hover {
                background: transparent;
            }
            .datagrid-table-empty-row:hover {
                background: transparent;
            }
        }
        th,
        td {
            border: none;
            font-size: $main-font-size;
            line-height: 125%;
            padding: 0.4rem 0.6rem;
            vertical-align: baseline;
            .input-group-disabled {
                max-width: 100%;
            }
            .choices__item.choices__item--selectable {
                overflow: visible;
                white-space: normal;
            }
            .choices__item {
                justify-content: flex-end;
                padding-right: 0;
                span {
                    text-align: right;
                }
            }
            .formio-editor-read-only-content,
            .disabledValueContainer {
                text-align: right;
                justify-content: flex-end;
            }
            .input-group-disabled {
                margin-bottom: 0;
            }
            .choices__list {
                display: flex;
                justify-content: flex-end;
            }
            .input-group {
                justify-content: flex-end;
            }
            .formio-component-datetime {
                grid-template-columns: 1fr;
            }
            .formio-component-htmlelement {
                text-align: right;
            }
            .input-as-status {
                justify-content: flex-end;
                .disabledValueContainer {
                    font-size: $main-font-size;
                }
            }
            .formio-component-textarea,
            .formio-component-number,
            .formio-component-select,
            .formio-component-selectComponentExtended,
            .formio-component-textfield {
                grid-template-columns: 1fr;
            }
            .choices__inner {
                background: transparent;
                background-color: transparent;
                border: none;
                padding: 0;
                margin: 0;
                min-height: auto;
                font-size: 1.2rem;
                color: var(--form-text-color);
                font-weight: 400;
                line-height: 125%;
                div {
                    background: transparent;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    font-size: 1.2rem;
                    color: var(--form-text-color);
                    font-weight: 400;
                    line-height: 125%;
                }
                span {
                    background: transparent;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    font-size: 1.2rem;
                    color: var(--form-text-color);
                    font-weight: 400;
                    line-height: 125%;
                }
            }
            .field-required:after, .tab-error::after {
                display: none;
            }
        }
        tr {
            &:has(td > .loan-status_INACTIVE) {
                a,
                .form-control {
                    color: #6E6E73 !important;
                }
            }
        }
        .form-control {
            font-size: 1.1rem;
            @include input-like-text-field;
            border-radius: 0;
            line-height: 1.6;
        }
        .formio-component-datetime {
            .form-control[type=‘hidden’]+.form-control {
                border-radius: 0;
            }
        }
        a {
            padding: 0.18rem 0;
            display: inline-block;
        }
    }
}



.formio-component-datagrid:not(.datagrid-disabled-class) {
    &.input-group-disabled {
        .datagrid-table {
            border-collapse: unset !important;
            padding: 0 !important;
            margin-bottom: 20px;

            th {
                padding-left: 10px !important;
                padding-right: 10px !important;
                text-align: right;

                &:first-child {
                    text-align: left;
                }
            }

            td {
                border: unset !important;
                background-color: var(--header-panel-bg);
                border-radius: 0.47rem;
                padding-left: 10px !important;
                padding-right: 10px !important;

                &:first-child {
                    .input-group-disabled {
                        justify-content: flex-start;
                        text-align: left;
                    }
                }

                .input-group-disabled {
                    margin-bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;
                }
                .field-required:after, .tab-error::after {
                    display: none;
                }

                .formio-component-select {
                    input {
                        display: none;
                    }
                }

            }
        }
    }

    & > label.field-required.control-label--hidden {
        display: none !important;
    }

    .datagrid-table {
        border-collapse: unset !important;
        padding: 0 !important;

        td {
            border: unset !important;

            .form-group:not(.input-group-disabled) {
                margin-bottom: 0 !important;

                &.formio-component-label-hidden {
                    grid-template-columns: 1fr 16px;

                    label.field-required.control-label--hidden {
                        top: -6px;
                    }
                }
            }
            .field-required:after, .tab-error::after {
                display: none;
            }
        }

        tfoot {
            tr {
                td {
                    padding: 1rem 0 2.2rem !important;
                }
                .field-required:after, .tab-error::after {
                    display: none;
                }
            }
        }
    }

}

