// Your variable overrides
$black: #1d1d1f;
$blue: #0a65ed;
$red: #f73743;
$main-green: #3FE579;
$main-gray: #9B9BA4;
$main-red: #F33F4A;
$main-yellow: #E3DB40;

//body
$body-color: #0d0f1c;

$h1-font-size: 2.8rem;

$input-border-radius: 0.35rem;
$border-radius: 0.35rem;

$screen-size-coefficient: 0.3vw;

$main-font-size: 1.2rem;

//forms

$max-inputs-width: 36rem;
$form-label-color: #6E6E73;
$label-margin-bottom: 0.24rem;
$input-disabled-margin-bottom: 0.6rem;
$input-margin-bottom: 0.6rem;
$input-margin-top: 0.6rem;

$input-bg: white;
$input-disabled-bg: transparent;
$input-border-width: 0.09rem;
$input-border-color: #d2d2d7;
$input-border-color-hover: #dadee2;
$input-focus-border-color: #9CE4A3;
$input-font-size: 0.94rem;
$input-line-height: 1.65;
$input-group-addon-bg: $input-bg;
$input-color: $black;
$input-height: 2.58rem;

$input-check-label-color: #8b959e;

$form-check-input-gutter: 0.65rem;

$form-group-margin-bottom: 1.18rem;

$grid-gutter-width: 1.18rem;

$card-border-width: 0;

$nav-tabs-border-width: 0.18rem;
$nav-tabs-border-color: white;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-border-color: white white $blue;
$nav-tabs-link-active-color: $blue;

$card-spacer-x: 0;
$card-spacer-y: 0.6rem;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: transparent;
$card-cap-bg: white;

$btn-padding-x: 1.47rem;

$header-panel-bg: #F5F5F7;
