main[pagename="borrowers"] {
    .formio-component-container.formio-component-address {
        .formio-component-columns.row {
            display: block;
            .col-md-10,
            .col-md-2 {
                max-width: 100%;
            }
        }
    }

    .formio-component-form {
        .formio-form > .row.formio-component-columns:not(.common-width) {
            &.align-items-end {
                align-items: center !important;
            }

            & > .col-md-6:first-child {
                padding: 0 2.18rem;
            }
        }
    }
}
