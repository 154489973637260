main[pagename="campaigns"] {
  .formio-component-form {
    .formio-form > div:not(.header-panel) > .card.card-body {
        padding: 0 1.6rem;
    }
  }
}

main[pagename="products"] {
  .formio-component-form {
    .formio-form > div:not(.header-panel) > .card.card-body {
      padding: 0 1.6rem;
    }
  }
}