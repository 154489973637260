.form-control {
    font-size: $main-font-size;
    padding: 0.7rem;
    min-height: 3.1rem;
    font-weight: 400;
    line-height: 125%;

    &+input {
        background: var(--input-bg);
    }
    
    &:hover:not(.is-invalid) {
        border-color: var(--input-border-color-hover);
    }

    &:active,
    &:focus:not(.is-invalid) {
        box-shadow: none;
        border-color: var(--input-border-color-focus);
        border-width: 0.2rem;

        &+.input-group-append {
            border-color: var(--input-border-color-focus);
            border-width: 0.2rem;
            border-left: none;
        }
    }

    +.input-group-append {
        display: flex;
        align-items: center;
        border: $table-border;
        border-radius: $border-radius;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-right: 1.3rem;

        .input-suffix {
            font-size: $main-font-size;
            padding-left: 0.3rem;
            border-left: none;
            color: var(--label-color);
            line-height: 0;
        }
    }

    &[disabled],
    &:disabled {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-bg;
        padding: 0;
        +.input-group-append{
            border: none;
            padding: 0;

            .input-suffix{
                color: var(--form-text-color);
            }
        }

        +.input-group-prepend{
            background-color: $input-disabled-bg;
            border-color: $input-disabled-bg;
            border-radius: $input-border-radius 0 0 $input-border-radius;

            .input-suffix{
                background-color: transparent;
                border: none;
            }
        }

        &:active{
            box-shadow: none;
        }
    }
}

.input-group-disabled {
    .input-suffix {
        font-size: $main-font-size;
        margin-left: 0.4rem;
        border-left: none;
        color: var(--form-text-color);
        line-height: 125%;
    }
}

input.form-control {
    background: var(--input-bg);
    &:focus {
        background: var(--input-bg);
    }
    & + .input-group-append {
        background: var(--input-bg);
    }
}

.input-group:has(> .input-group-append) {
    .form-control {
        &.is-invalid {
            border-right: none !important;
            padding-right: calc(1.65em + 0.75rem) !important;
            &+.input-group-append {
                border-color: $red;
                border-left: none;
            }
    
            &:focus {
                &+.input-group-append {
                    box-shadow: none;
                }
                box-shadow: none;
            }
        }
    }
}
